<template>
  <master-layout
    :pageTitle="$t('pageTitle.Tutorial')"
    :showBack="false"
    :showMenu="false"
  >
    <div class="ion-text-center">
      <button @click="skip" class="px-4 py-3 button-primary mb-2">
        {{ $t("generic.close") }}
      </button>
      <br />
      <button @click="noShowAgain" class="px-4 py-3 button-primary">
        {{ $t("generic.no_show_again") }}
      </button>
    </div>
    <swiper
      :slides-per-view="1"
      :loop="true"
      :pagination="{ clickable: true }"
      navigation
      :zoom="true"
    >
      <swiper-slide
        v-for="n in whappyClientConfig.numTutorialImages"
        v-bind:key="n"
      >
        <div v-if="whappyClientConfig.name == 'Axerta'" class="ion-text-center">
          <img
            :src="
              whappyClientAsset +
              'img/tutorial/' +
              n +
              (n == 4 ||
              n == 5 ||
              n == 7 ||
              n == 9 ||
              n == 10 ||
              n == 11 ||
              n == 12 ||
              n == 13
                ? '.gif'
                : '.png')
            "
            @error="handleImgError"
          />
        </div>
        <div v-else-if="whappyClientConfig.name == 'Yoube'">
          <img
            :src="whappyClientAsset + 'img/tutorial/' + n + '.jpg'"
            @error="handleImgError"
          />
        </div>
        <div v-else class="ion-text-center">
          <img
            :src="whappyClientAsset + 'img/tutorial/' + n + '.png'"
            @error="handleImgError"
          />
        </div>
      </swiper-slide>
    </swiper>
  </master-layout>
</template>

<script>
import { defineComponent } from "vue";

import "swiper/swiper-bundle.min.css";
import "swiper/components/pagination/pagination.scss";
import "@ionic/vue/css/ionic-swiper.css";

import { IonIcon, IonButton, IonButtons } from "@ionic/vue";

import { arrowForward } from "ionicons/icons";
import ApiService from "../common/service.api";
import { translate } from "../common/helper.auth";
import SwiperCore, { Navigation, Pagination, Zoom } from "swiper";
import { IonContent, IonPage, IonicSwiper } from "@ionic/vue";
import { Swiper, SwiperSlide } from "swiper/vue";

SwiperCore.use([IonicSwiper, Navigation, Pagination, Zoom]);

export default {
  name: "Tutorial",
  components: {
    // IonIcon,
    // IonButton,

    IonButton,
    IonButtons,
    IonIcon,
    Swiper,
    SwiperSlide,
    IonContent,
    IonPage,
  },
  created() {},
  methods: {
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    skip() {
      if (this.settings.ragione_sociale == "Axerta") {
        this.$router.push("/axerta/lista-ordini");
      } else {
        this.$router.push("/campaigns");
      }
    },
    noShowAgain() {
      this.$root.setLocalStorage("shown_tutorial", "1").then((s) => {});
      if (this.settings.ragione_sociale == "Axerta") {
        this.$router.push("/axerta/lista-ordini");
      } else {
        this.$router.push("/campaigns");
      }
      this.skip();
    },
  },
  ionViewWillEnter() {},
};
</script>

<style lang="scss" scoped>
.swiper-slide img {
  margin-top: 20px;
  height: calc(100vh - 220px);
  margin-bottom: 30px;
}
</style>
